import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  myHabits: {
    width: '23.875rem',
    height: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    borderRadius: '20px',
    boxShadow: '4px 4px 20px 2px rgba(0, 0, 0, 0.25)',
    minWidth: '23.875rem',
    flexDirection: 'column',
    backgroundColor: ({ color }) => color,
    [theme.breakpoints.down(1280)]: {
      width: '100%',
      boxShadow: '0px 0px 0px 0px white',
      borderRadius: 0,
    },
  },
  habitDetail: {
    marginTop: '2rem',
    height: '100%',
    padding: '0 2rem 1rem 1.5rem',
    backgroundColor: '#F4F3F3',
    borderRadius: '20px',
    width: '100%',
    [theme.breakpoints.down(1280)]: {
      width: '100%',
      borderRadius: '20px 20px 0px 0px',
    },
  },
  BackButton: {
    height: '3.25rem',
    width: '3.25rem',
  },

  backIcon: {},

  categoryIcon: {
    height: '3.75rem',
    width: '3.75rem',
    "& img": {
      width: "100%"
    }
  },
  titleRectangle: {
    display: 'flex',
    alignItems: 'center',
    gap: '3rem',
    marginTop: '2rem',
    marginLeft: '2rem',
  },
  habitTitle: {
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.83,
    color: '#FFFFFF',
    [theme.breakpoints.down(1280)]: {
      width: '75%',
      textAlign: 'left',
    },
  },
  habitCategory: {
    height: '1.938rem',
    paddingTop: '1.375rem',
    margin: '0 0 2rem 2rem',
    flexGrow: 0,
    borderRadius: '20px',
    textAlign: 'left',
    fontFamily: 'Montserrat',
    fontSize: '1.75rem',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.57,
    letterSpacing: 'normal',
    color: '#393E44',
    [theme.breakpoints.down(1280)]: {
      margin: '0 0 2rem ',
      textAlign: 'center',
    },
  },
  habitCard: {
    borderRadius: '20px',
    backgroundColor: 'FFFFFF',
    width: '18.813rem',
    boxShadow: '5.3px 5.3px 10.5px 2.6px rgba(0, 0, 0, 0.2)',
    margin: '3.875rem 0rem 0.25rem 1rem',
    padding: '1.063rem 0.712rem 5.235rem 1.063rem',
    height: '5rem',
    display: 'flex',
    gap: '1rem',
  },
  totalMetrics: {
    float: 'left',
  },
  vectorPurple: {
    width: '5.507rem',
    height: '0.652rem',
    flexGrow: 0,
    backgroundColor: ({ color }) => color,
    borderRadius: '5.1px',
  },
  vectorGray: {
    width: '12.507rem',
    height: '0.652rem',
    flexGrow: 0,
    backgroundColor: 'lightgray',
    borderRadius: '5.1px',
  },
  totalAmount: {
    fontFamily: 'NotoSans, Montserrat',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '393E44',
    paddingBottom: '0.7rem',
  },
  averageSpending: {
    fontFamily: 'NotoSans, Montserrat',
    fontSize: '0.875rem',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.1,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  backToHabits: {
    height: '3.227rem',
    backgroundColor: '#008296',
    borderRadius: '10.7px',
    marginTop: '3rem',
  },
  backToHabitsText: {
    fontSize: '1rem',
    color: '#FFFFFF',
    width: '100%',
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
  },
  buttonBackContainer: {
    width: '3.25rem',
    height: '3.25rem',
    backgroundColor: '#00000073',
    borderRadius: '7px',
    left: '2.5rem',
    top: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      width: '4rem',
      height: '4rem',
      color: 'white',
    },
  },
  buttonBack1: {},
  buttonBack2: {
    width: '100%',
    height: '100%',
  },
  tableContainer: {
    height: '13rem',
  },
  addGoal: {
    height: '3.227rem',
    backgroundColor: '#064656',
    borderRadius: '10.7px',
    marginTop: '1rem',
    [theme.breakpoints.down(1280)]: {
      marginTop: '3rem',
    },
  },
  addGoalText: {
    fontSize: '1rem',
    color: '#E2ECEF',
    width: '100%',
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
  },
  buttonAddGoal: {
    width: '100%',
    height: '100%',
  },
  // PopUp
  popUpContainer: {
    zIndex: 1000,
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    background: '#000000CC',
    height: '100rem',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '10%',
    [theme.breakpoints.down(1280)]: {
      paddingTop: '35%',
    },
  },
  hiddenDisplay: {
    display: 'none',
  },
  outerBorder: {
    width: '43.938rem',
    height: '16rem',
    borderRadius: '40px',
    border: '1px solid #FFFFFF',
    padding: '0.25rem',
    [theme.breakpoints.down(1280)]: {
      width: '21.063rem',
      height: '18.188rem',
    },
  },
  popUp: {
    width: '100%',
    height: '100%', //"66%";
    backgroundColor: '#FFFFFFE6',
    borderRadius: '35px',
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    [theme.breakpoints.down(1280)]: {
      borderRadius: '35px',
    },
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rokipediaPopUpTitle: {
    fontFamily: 'Nunito, Montserrat',
    fontSize: '1rem',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 'normal',
    color: '#393E44',
    margin: '0 70% 0 0 ',
    [theme.breakpoints.down(1280)]: {
      margin: '0 30% 0 3% ',
    },
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontSize: '0.875rem',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '1.43',
    color: '#393E44',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(1280)]: {
      marginTop: '1rem',
    },
  },
  goalInput: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonGotItContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(1280)]: {
      margin: '1.5rem 0 0 0',
    },
  },
  buttonGotIt: {
    margin: '1.2rem 0 0 0',
    width: '3rem',
  },
  popUpVector: {
    width: '100%',
    height: '0.125rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '2px',
    margin: '0.7rem 0 0 0',
    [theme.breakpoints.down(1280)]: {},
  },
}));
